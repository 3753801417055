<template>
  <div>
    <div class="d-flex justify-end flex-sm-row flex-column mx-3">
      <v-btn @click="downloadExcel(summaryByProductTime.details)">
        <v-icon class="mr-2">mdi-microsoft-excel</v-icon> Download
      </v-btn>
    </div>

    <div class="mt-5">
      <v-tabs fixed-tabs v-model="tab" :show-arrows="isMobile">
        <v-tab v-for="type in types" :key="type">
          {{ type }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" touchless>
        <v-tab-item class="ma-0 ma-md-5" v-for="type in types" :key="type">
          <sold-events
            v-if="type == 'sold'"
            :eventList="groups[type].events"
            :date="date"
            :domain="domain"
          />
          <adjustment-events
            v-if="type == 'adjustments'"
            :eventList="groups[type].events"
            :date="date"
            :domain="domain"
          />
          <purchase-events
            v-if="type == 'purchases'"
            :eventList="groups[type].events"
            :date="date"
            :domain="domain"
          />
          <process-input-events
            v-if="type == 'inputs'"
            :eventList="groups[type].events"
            :date="date"
            :domain="domain"
          />
          <process-output-events
            v-if="type == 'outputs'"
            :eventList="groups[type].events"
            :date="date"
            :domain="domain"
          />
          <process-events
            v-if="type == 'process'"
            :eventList="groups['process'].events"
            :date="date"
            :domain="domain"
            v-model="processData"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { json2excel } from "js2excel";

import { parseDomain } from "parse-domain";
const SoldEvents = () => import("@/components/inventory/SoldEvents.vue");
const AdjustmentEvents = () =>
  import("@/components/inventory/AdjustmentEvents.vue");
const ProcessEvents = () => import("@/components/inventory/ProcessEvents.vue");
const ProcessInputEvents = () =>
  import("@/components/inventory/ProcessInputEvents.vue");
const ProcessOutputEvents = () =>
  import("@/components/inventory/ProcessOutputEvents.vue");
const PurchaseEvents = () =>
  import("@/components/inventory/PurchaseEvents.vue");

export default {
  components: {
    SoldEvents,
    AdjustmentEvents,
    ProcessInputEvents,
    ProcessOutputEvents,
    PurchaseEvents,
    ProcessEvents,
  },
  props: {
    summaryByProductTime: {
      type: Object,
      required: true,
    },
    date: {
      type: Date,
    },
  },
  data: () => ({
    headers: [
      { text: "Inventory ID", value: "inventoryId" },
      { text: "Time", value: "time" },
      { text: "Type", value: "event" },
      { text: "Quantity", value: "quantity" },
      { text: "Cost", value: "value" },
      { text: "Unit Cost", value: "unitCost" },
    ],
    domain: null,
    types: [],
    groups: {},
    tab: 0,
    hasProcess: false,
    processData: null,
  }),
  mounted() {
    this.groupByType();

    this.hasProcess =
      this.types.includes("inputs") || this.types.includes("outputs");

    const parseResult = parseDomain(window.location.hostname);
    const { domain } = parseResult;
    this.domain = domain;
  },
  computed: {
    ...mapGetters("inventoryCostAdj", ["costAdjustmentService"]),
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
  },
  methods: {
    ...mapActions("messages", ["addErrorMessage"]),
    downloadExcel(data) {
      const sheets = Object.values(data);
      let mergedSheetsData = [];
      sheets.forEach( (s) => {
        mergedSheetsData = mergedSheetsData.concat(s.events);
      });
      try {
        json2excel({
          data: mergedSheetsData,
          name: "inventory-events",
          formateDate: "yyyy-mm-dd",
        });

        if (
          this.hasProcess &&
          this.processData &&
          this.processData.length > 0
        ) {
          json2excel({
            data: this.processData,
            name: "process-report",
            formateDate: "yyyy-mm-dd",
          });
        }
      } catch (e) {
        console.error(e)
        this.addErrorMessage("Error exporting inventory events");
      }
    },
    groupByType() {
      this.types = [];
      this.groups = this.summaryByProductTime.details
      this.types = Object.keys(this.groups);
      this.types.sort();
    },
  },
};
</script>

<style></style>
